<template>
  <div>
    <header-top-dashboard primary></header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col cols="12" class="mt-n3">
          <paginated-table
            create-new-item-label="Create New Product"
            :headers="headers"
            :items="items"
            :pagination-details="paginationDetails"
            :showSearchBox="true"
            :searchPlaceholder="'Search product name...'"
            @updateSearchValue="searchByValue"
            @createNewItem="createNewItem"
            @editItem="editItem"
            @fetchNextPage="fetchDataByUrl(paginationDetails.nextPageUrl)"
            @fetchPreviousPage="
              fetchDataByUrl(this.paginationDetails.previousPageUrl)
            "
            @updateItemsPerPage="updateItemsPerPage"
            @updateItemStatus="updateItemStatus"
            @updateSelectedPage="updateSelectedPage"
          ></paginated-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";
import PaginatedTable from "@/components/Tables/PaginatedTable.vue";
export default {
  name: "products",
  components: {
    HeaderTopDashboard,
    PaginatedTable,
  },
  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "formattedName",
        },
        { text: "Sub Category", value: "subCategory" },
        { text: "Retail Price", value: "retailPrice" },
        { text: "Stock", value: "stock" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [],
      paginationDetails: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        pageStart: 0,
        pageStop: 0,
        itemsLength: 0,
        firstPageUrl: "",
        lastPageUrl: "",
        nextPageUrl: "",
        previousPageUrl: "",
        links: [],
        path: "",
      },
      searchValue: "",
    };
  },
  computed: {
    ...mapStores(useSessionStore),
  },
  methods: {
    createNewItem() {
      this.$router.push("/products/create");
    },

    editItem(item) {
      this.$router.push({
        name: "Edit Product",
        params: {
          id: `${item.id}`,
        },
      });
    },

    fetchDataByUrl(url) {
      if (!url) {
        // TODO - print alert.
        return;
      }

      if (!url.includes("search?")) {
        this.paginationDetails.loading = true;
        this.$http
          .get(url, this.sessionStore.getHttpConfig)
          .then((result) => {
            this.items.splice(0);

            for (let i = 0; i < result.data.data.length; i++) {
              const product = result.data.data[i];

              let productImage = "";
              if (product.images.length > 0) {
                productImage = `${this.sessionStore.getApiHost}${product.images[0].image_uri}`;
              }

              let subCategoryName = "";
              if (product.group && product.group.sub_category) {
                subCategoryName = product.group.sub_category.name;
              }

              this.items.push({
                id: product.id,
                productImage,
                name: product.name,
                formattedName: `${product.name} ${product.package_units} x ${product.weight}`,
                subCategory: subCategoryName,
                retailPrice: product.retail_price,
                stock: product.stock_on_hand,
                status: product.status,
              });
            }

            this.setPaginationDetails(result.data);
          })
          .catch((error) => {
            this.handleHttpError(
              error,
              "An error occurred while loading the data. Please try again later or contact support."
            );
          })
          .finally(() => {
            this.paginationDetails.loading = false;
          });
      } else {
        this.paginationDetails.loading = true;

        const body = [
          {
            field: "name",
            operator: "like",
            value: this.searchValue,
          },
        ];

        this.$http
          .post(url, body, this.sessionStore.getHttpConfig)
          .then((result) => {
            this.items.splice(0);

            for (let i = 0; i < result.data.data.length; i++) {
              const product = result.data.data[i];

              let productImage = "";
              if (product.images.length > 0) {
                productImage = `${this.sessionStore.getApiHost}${product.images[0].image_uri}`;
              }

              let subCategoryName = "";
              if (product.group && product.group.sub_category) {
                subCategoryName = product.group.sub_category.name;
              }

              this.items.push({
                id: product.id,
                productImage,
                name: product.name,
                formattedName: `${product.name} ${product.package_units} x ${product.weight}`,
                subCategory: subCategoryName,
                retailPrice: product.retail_price,
                stock: product.stock_on_hand,
                status: product.status,
              });
            }

            this.setPaginationDetails(result.data);
          })
          .catch((error) => {
            this.handleHttpError(
              error,
              "An error occurred while loading the data. Please try again later or contact support."
            );
          })
          .finally(() => {
            this.paginationDetails.loading = false;
          });
      }
    },

    searchByValue(val) {
      this.searchValue = val;

      if (!val) {
        this.fetchDataByUrl("/products");
        return;
      }

      const body = [
        {
          field: "name",
          operator: "like",
          value: val,
        },
      ];

      this.$http
        .post("/products/search", body, this.sessionStore.getHttpConfig)
        .then((result) => {
          this.items.splice(0);

          for (let i = 0; i < result.data.data.length; i++) {
            const product = result.data.data[i];

            let productImage = "";
            if (product.images.length > 0) {
              productImage = `${this.sessionStore.getApiHost}${product.images[0].image_uri}`;
            }

            let subCategoryName = "";
            if (product.group && product.group.sub_category) {
              subCategoryName = product.group.sub_category.name;
            }

            this.items.push({
              id: product.id,
              productImage,
              name: product.name,
              formattedName: `${product.name} ${product.package_units} x ${product.weight}`,
              subCategory: subCategoryName,
              retailPrice: product.retail_price,
              stock: product.stock_on_hand,
              status: product.status,
            });
          }

          this.setPaginationDetails(result.data);
        })
        .catch((error) => {
          console.error("Error: ", error);
          this.$notify.success("An error occurred while searching products.");
        });
    },

    updateItemStatus(item, status) {
      const body = {
        status,
      };

      this.$http
        .patch(`/products/${item.id}`, body, this.sessionStore.getHttpConfig)
        .then(() => {
          this.fetchDataByUrl("/products");
          this.$notify.success("Status updated successfully.");
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            "An error occurred while updating the status."
          );
        });
    },

    updateItemsPerPage(newValue) {
      const searchParams = new URLSearchParams({
        size: newValue,
      });

      const url = `${this.paginationDetails.path}?${searchParams.toString()}`;

      this.fetchDataByUrl(url);
    },
  },
  mounted() {
    this.fetchDataByUrl("/products");
  },
};
</script>
